/* .card {
    padding: 2rem;
} */

.bodyContainer {
    margin-top: 3rem;
    margin-bottom: 3rem;
}

/* html {
    width: 100%;
} */

@media (min-width: 320px) and (max-width: 480px) {
    h4 {
        font-size: 1.1rem;
    }
}