.innericon {
    width: .7rem;
    margin-bottom: .2rem;
}

/* Custom CSS */

.maintain {
    min-height: 100%;
    height: 800px;
}

.notic {
    align-items: center;
    vertical-align: middle;
    margin: 0 60px;
}

/* @media only screen and (max-width: 1920px) and (min-width: 1240px) {
    .notic {
      height: 90vh;
    }
  } */

@media only screen and (min-width: 320px) and (max-width: 420px) {
    .notic {
        margin: 0 20px;
        text-align: center;
    }
}

.min-w-0 {
    min-width: 0;
}

.min-w-full {
    min-width: 100%;
}

.nugstart {
    padding: 5px 20px;
    background-color: #F0F0F0;
    align-items: center;
    margin-right: 0 !important;
}

.nug {
    padding-left: 10px;
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 0.8rem;
    font-family: 'Poppins', sans-serif;
}

.subbannertitle {
    font-size: 0.8rem;
    font-family: 'Poppins', sans-serif;
}

.nugctext {
    font-size: 0.8rem;
    font-family: 'Poppins', sans-serif;
}

.nugtop {
    align-items: center;
    padding-top: 10px;
}

.complaintlogo {
    width: auto;
    margin-bottom: 1rem;
    align-items: center;
}

.hypernug {
    color: black;
    font-style: normal;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
}

.hypernug:hover {
    color: #FF6B00;
}

.nugstart {
    text-align: left;
}