.dataCreateForm {
    width: 100%;
    padding: 4rem;
    margin: 0,
}

@media (min-width:320px) and (max-width: 480px) {
    .dataCreateForm {
        padding: 1rem;
    }
}